import axios from "axios";
import { command, find, findOne, authorizationHeaders } from "../utils/api";

const JOBS_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/jobs`;
const CANDIDATES_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/candidates`;

export const SAVE_JOB_POSTING_DRAFT = "SAVE_JOB_POSTING_DRAFT";
export const SAVE_JOB_POSTING_DRAFT_COMPLETED =
  "SAVE_JOB_POSTING_DRAFT_COMPLETED";
export const SAVE_JOB_POSTING_DRAFT_FAILED = "SAVE_JOB_POSTING_DRAFT_FAILED";

export const saveJobPostingDraft = command(
  JOBS_BASE_URL + "/saveJobPostingDraft",
  SAVE_JOB_POSTING_DRAFT,
  SAVE_JOB_POSTING_DRAFT_COMPLETED,
  SAVE_JOB_POSTING_DRAFT_FAILED
);

export const SAVE_JOB_POSTING = "SAVE_JOB_POSTING";
export const SAVE_JOB_POSTING_COMPLETED = "SAVE_JOB_POSTING_COMPLETED";
export const SAVE_JOB_POSTING_FAILED = "SAVE_JOB_POSTING_FAILED";

export const saveJobPosting = command(
  JOBS_BASE_URL + "/saveJobPosting",
  SAVE_JOB_POSTING,
  SAVE_JOB_POSTING_COMPLETED,
  SAVE_JOB_POSTING_FAILED
);

export const SAVE_JOB_EXCLUSIVE_TO = "SAVE_JOB_EXCLUSIVE_TO";
export const SAVE_JOB_EXCLUSIVE_TO_COMPLETED = "SAVE_JOB_EXCLUSIVE_TO_COMPLETED";
export const SAVE_JOB_EXCLUSIVE_TO_FAILED = "SAVE_JOB_EXCLUSIVE_TO_FAILED";

export const saveJobExclusiveTo = command(
  JOBS_BASE_URL + "/allowRecruitersForJob",
  SAVE_JOB_EXCLUSIVE_TO,
  SAVE_JOB_EXCLUSIVE_TO_COMPLETED,
  SAVE_JOB_EXCLUSIVE_TO_FAILED
);

export const REMOVE_JOB_POSTING = "REMOVE_JOB_POSTING";
export const REMOVE_JOB_POSTING_COMPLETED = "REMOVE_JOB_POSTING_COMPLETED";
export const REMOVE_JOB_POSTING_FAILED = "REMOVE_JOB_POSTING_FAILED";

export const removeJobPosting = command(
  JOBS_BASE_URL + "/removeJobPosting",
  REMOVE_JOB_POSTING,
  REMOVE_JOB_POSTING_COMPLETED,
  REMOVE_JOB_POSTING_FAILED
);

export const CHANGE_RETAIL_TERM_FOR_JOB = "CHANGE_RETAIL_TERM_FOR_JOB";
export const CHANGE_RETAIL_TERM_FOR_JOB_COMPLETED =
  "CHANGE_RETAIL_TERM_FOR_JOB_COMPLETED";
export const CHANGE_RETAIL_TERM_FOR_JOB_FAILED =
  "CHANGE_RETAIL_TERM_FOR_JOB_FAILED";

export const changeRetailTermForJob = command(
  JOBS_BASE_URL + "/changeRetailTermForJob",
  CHANGE_RETAIL_TERM_FOR_JOB,
  CHANGE_RETAIL_TERM_FOR_JOB_COMPLETED,
  CHANGE_RETAIL_TERM_FOR_JOB_FAILED
);

export const GET_ACTIVE_JOB_CAST = "GET_ACTIVE_JOB_CAST";
export const GET_ACTIVE_JOB_CAST_COMPLETED = "GET_ACTIVE_JOB_CAST_COMPLETED";
export const GET_ACTIVE_JOB_CAST_FAILED = "GET_ACTIVE_JOB_CAST_FAILED";

export const getJobCasts = find(
  JOBS_BASE_URL + "/getUserJobCasts",
  GET_ACTIVE_JOB_CAST,
  GET_ACTIVE_JOB_CAST_COMPLETED,
  GET_ACTIVE_JOB_CAST_FAILED
);

export const ACTIVE_JOB_CAST = "ACTIVE_JOB_CAST";
export const ACTIVE_JOB_CAST_COMPLETED = "ACTIVE_JOB_CAST_COMPLETED";
export const ACTIVE_JOB_CAST_FAILED = "ACTIVE_JOB_CAST_FAILED";

export const activeJobCast = command(
  JOBS_BASE_URL + "/activeJobCast",
  ACTIVE_JOB_CAST,
  ACTIVE_JOB_CAST_COMPLETED,
  ACTIVE_JOB_CAST_FAILED
);

export const REMOVE_JOB_CAST = "REMOVE_JOB_CAST";
export const REMOVE_JOB_CAST_COMPLETED = "REMOVE_JOB_CAST_COMPLETED";
export const REMOVE_JOB_CAST_FAILED = "REMOVE_JOB_CAST_FAILED";

export const removeJobCast = command(
  JOBS_BASE_URL + "/removeJobCast",
  REMOVE_JOB_CAST,
  REMOVE_JOB_CAST_COMPLETED,
  REMOVE_JOB_CAST_FAILED
);

export const FIND_JOBS = "FIND_JOBS";
export const FIND_JOBS_COMPLETED = "FIND_JOBS_COMPLETED";
export const FIND_JOBS_FAILED = "FIND_JOBS_FAILED";

export const findJobs = find(
  JOBS_BASE_URL + "/findWithCandidates",
  FIND_JOBS,
  FIND_JOBS_COMPLETED,
  FIND_JOBS_FAILED
);

export const FIND_ALL_JOBS = "FIND_ALL_JOBS";
export const FIND_ALL_JOBS_COMPLETED = "FIND_ALL_JOBS_COMPLETED";
export const FIND_ALL_JOBS_FAILED = "FIND_ALL_JOBS_FAILED";

export const findAllJobs = find(
  JOBS_BASE_URL + "/findWithInvitedCandidates",
  FIND_ALL_JOBS,
  FIND_ALL_JOBS_COMPLETED,
  FIND_ALL_JOBS_FAILED
);

export const FILTER_JOB_CANDIDATES = "FILTER_JOB_CANDIDATES";
export const FILTER_JOB_CANDIDATES_COMPLETED =
  "FILTER_JOB_CANDIDATES_COMPLETED";
export const FILTER_JOB_CANDIDATES_FAILED = "FILTER_JOB_CANDIDATES_FAILED";

export const findCandidatesForJobByStatus = find(
  CANDIDATES_BASE_URL + "/candidatesForJobByStatus",
  FILTER_JOB_CANDIDATES,
  FILTER_JOB_CANDIDATES_COMPLETED,
  FILTER_JOB_CANDIDATES_FAILED
);

export const FIND_JOB = "FIND_JOB";
export const FIND_JOB_COMPLETED = "FIND_JOB_COMPLETED";
export const FIND_JOB_FAILED = "FIND_JOB_FAILED";

export const findJob = findOne(
  JOBS_BASE_URL + "/find",
  FIND_JOB,
  FIND_JOB_COMPLETED,
  FIND_JOB_FAILED
);

export const ACCEPT_JOB_CAST_TERMS = "ACCEPT_JOB_CAST_TERMS";
export const ACCEPT_JOB_CAST_TERMS_COMPLETED =
  "ACCEPT_JOB_CAST_TERMS_COMPLETED";
export const ACCEPT_JOB_CAST_TERMS_FAILED = "ACCEPT_JOB_CAST_TERMS_FAILED";

export const acceptJobCastTerms = command(
  JOBS_BASE_URL + "/acceptJobCastTerms",
  ACCEPT_JOB_CAST_TERMS,
  ACCEPT_JOB_CAST_TERMS_COMPLETED,
  ACCEPT_JOB_CAST_TERMS_FAILED
);

export const CHANGE_FAVORITE_JOB_STATUS = 'CHANGE_FAVORITE_JOB_STATUS';
export const CHANGE_FAVORITE_JOB_STATUS_COMPLETED = 'CHANGE_FAVORITE_JOB_STATUS_COMPLETED';
export const CHANGE_FAVORITE_JOB_STATUS_FAILED = 'CHANGE_FAVORITE_JOB_STATUS_FAILED';

export const changeFavoriteJobStatus = command(
  JOBS_BASE_URL + "/ChangeJobFavoriteStatus",
  CHANGE_FAVORITE_JOB_STATUS,
  CHANGE_FAVORITE_JOB_STATUS_COMPLETED,
  CHANGE_FAVORITE_JOB_STATUS_FAILED
);

export async function fetchJobDetails(jobId) {
  if (!jobId) {
    throw new Error('jobId is required');
  }
  const { data } = await axios.get(`${JOBS_BASE_URL}/find`, {
    params: { jobId },
    headers: authorizationHeaders(),
  });
  return data;  
}